import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser } from "react-icons/fa";
import Skeleton from '@mui/material/Skeleton';
import LoadingOverlay from "react-loading-overlay";

//import './css/sideBar.scss';
import './css/elements.css';
import './css/dashboard.css';

import Client from './components/Client';
import Product from './components/Product';
import ProductsFilters from './components/ProductsFilters';
import { isEmpty } from 'validator';



let theme = createTheme();

class Products extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      products: null,
      productClasses: null,
      productCategories: null,
      productSubCategories: null,
      allergens: null,
      addNewProduct: false,
      loading: true
    };

    const { loading = false } = props;

    this.applyFilters = this.applyFilters.bind(this);
    this.applyCategory = this.applyCategory.bind(this);
    this.setAllergens = this.setAllergens.bind(this);
    this.setCategories = this.setCategories.bind(this);
    this.setSubCategories = this.setSubCategories.bind(this);
    this.toggleAdd = this.toggleAdd.bind(this);
    this.getData = this.getData.bind(this);
    this.productAdded = this.productAdded.bind(this);
    
  }

  getData ( filterData = null) {
    let queryData = {
      "limit": 20,
      "orderBy_modifiedOn": true
    }
    if (filterData != null)
      if (Object.keys(filterData).length !== 0) {
        if (filterData["limit"] !== undefined)
          filterData["limit"] = 20;
        if (filterData["orderBy_modifiedOn"] !== undefined)
          filterData["orderBy_modifiedOn"] = true;
        queryData = filterData;
      }

    let data = this.state.client.getProducts ( queryData );
    this.setState ( { products: data.products });

    let productsClasses = this.state.client.getProductsClasses ( );
    this.setClasses ( productsClasses.productClasses );

    let productsCategories = this.state.client.getProductsCategories ( );
    //this.setState ( { productCategories: productsCategories.productCategories });
    this.setCategories ( productsCategories.productCategories );

    let productsSubCategories = this.state.client.getProductsSubCategories ( );
    //this.setState ( { productSubCategories: productsSubCategories.productSubCategories });
    this.setSubCategories ( productsSubCategories.productSubCategories );

    //console.log ( productsSubCategories )

    let allergens = this.state.client.getAllergens ( );
    //this.setState ( { allergens: allergens.Allergens });
    this.setAllergens ( allergens.Allergens );
  }

  componentDidMount() {
    this.getData ( );
    this.setState ({loading: false});
  }

  componentWillUnmount() {    
  }

  setAllergens ( allergens ) {
    let a = { }
    if ( allergens !== null && allergens !== undefined ) {
      allergens.map ( (allergen) => {
        a [ allergen.iref ] = allergen;        
      });
      this.setState ( { allergens : a });
    }
  }

  setClasses ( classes ) {
    let a = { }
    if ( classes !== null && classes !== undefined ) {
      classes.map ( (productClass) => {
        a [ productClass.iref ] = productClass;        
      });
      this.setState ( { productClasses : a });
    }
  }

  setCategories ( categories ) {
    let a = { }
    if ( categories !== null && categories !== undefined ) {
      categories.map ( (category) => {
        a [ category.iref ] = category;        
      });
      this.setState ( { productCategories : a });
    }
  }

  setSubCategories ( subCategories ) {
    //console.log ( "setSubCategories-111" );
    //console.log (subCategories);
    let a = { }
    if ( subCategories !== null && subCategories !== undefined ) {
      subCategories.map ( (subCategory) => {
        //console.log ( subCategory );
        a [ subCategory.iref ] = subCategory;        
      });
      this.setState ( { productSubCategories : a });
      //console.log ( "setSubCategories" );
      //console.log ( this.state.productSubCategories );
    }
  }

  //Function to change the main Products filter state
  applyFilters ( queryData ) {
    //console.log ( "+++++++++++++++++++++++++++");
    //console.log ( queryData );
    if ( queryData ) {
      ////console.log ( queryData );
      this.setState ({filterData: queryData});
      let data = this.state.client.filterProducts ( queryData );
      this.setState ( { products: data.products });
    }
  }

  applyCategory ( category ) {
    if ( category !== undefined && category !== null ) {
      let queryData = { };
      if ( category !== "" ) queryData [ "category" ] = category;        
      let productsSubCategories = this.state.client.getProductsSubCategories ( queryData );
      this.setSubCategories ( productsSubCategories.productSubCategories );
    }
  }

  toggleAdd ( ) {
    let doAdd = this.state.addNewProduct;
    this.setState ( {addNewProduct: !doAdd} );
  }

  productAdded ( ) {
    let queryData = { };
    if ( this.state.filterData ) queryData = this.state.filterData;
    this.setState ( { products: null });
    //this.applyFilters ( queryData ); 
    this.toggleAdd ( false );  
    this.getData ( queryData ); 
    this.setState ( { filterData: queryData } );
    window.scrollTo(0, 0);
  }

  render() {
    let productsList;
    ////console.log ( this.state.products );
    let productsCounter = 0;
    if ( this.state.products ) {
      productsCounter = this.state.products.length;
      productsList = this.state.products.map ( (product) => {
        let productImage = "";        
        if ( product.productImage_product.length > 0 )
          if ( product.productImage_product[0].image ) {
            productImage = product.productImage_product[0].image;
            product.productImage_product.map ( (pImage) => {
              if ( pImage.isMain === true )
                productImage = pImage.image;
            });
          }

        return <Product
          key={product.iref}
          product={product}
          image={productImage}
          allergensList={this.state.allergens}
          productUpdated={this.productAdded}
        />
      });
    }

    ////console.log ( productsList);

    return (
      <LoadingOverlay active={this.state.loading} spinner text="Loading...">
 
      <div style={{ backgroundColor: "#273442", height: "100%" }}>
        <div className='sticky'>
          <ProductsFilters
          key = "productsFilters"
          applyFilters = {this.applyFilters}
          applyCategory = {this.applyCategory}
          toggleAdd = {this.toggleAdd}
          productsCounter = {productsCounter}
          classesList = {this.state.productClasses}
          categoriesList = {this.state.productCategories}
          subCategoriesList = {this.state.productSubCategories}
          allergensList = {this.state.allergens}
          buttonStatus = {this.state.addNewProduct}
          sx={{ position: 'sticky', top: 0}}
          />

        </div>

        { this.state.addNewProduct ?
        <Product
          key={0}
          product={undefined}
          allergensList={this.state.allergens}
          isNew={true}
          productUpdated={this.productAdded}
        />
        : ""
        }

        {this.loading ? <Skeleton animation="wave" variant="rounded" width={800} height={400} /> : productsList }

      </div>
      </LoadingOverlay>
    );

  }
}

export default Products;