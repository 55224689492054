import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";

import KImage from './components/KImage';
import Config from './components/ClientConfig';
import Client from './components/Client';
import BackOfficeConfig from './Config';
import swal from 'sweetalert';
import noImage from './images/noImage.png';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#273442",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

class DataRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {   
      client: new Client ( "none" ),   
      edit: false,
      row: {},
      nameValid: false,
      descriptionValid: false,
      image_off: noImage,
      somethingHasChanged: false,
    };

    
    this.handleEditClick = this.handleEditClick.bind(this);
    this.getImage = this.getImage.bind(this);
    this.getImage_off = this.getImage_off.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.pushData = this.pushData.bind(this);
    //this.classAdded = this.classAdded.bind(this);

  }

  componentDidMount() {
    let row = {};
    if ( this.props.row ) {
      row = this.props.row;
      this.setState ( { row: row } );
      if ( row.name.length > 0 ) this.setState ( {nameValid: true } );
      if ( row.description.length > 0 ) this.setState ( {descriptionValid: true } );
    }
    if ( this.props.edit ) this.setState ( { edit: true } );
  }

  componentDidUpdate ( prevProps ) {
    if ( this.props != prevProps ) {
      if ( this.props.row ) {
        let row = this.props.row;
        if ( row.name.length > 0 ) this.setState ( {nameValid: true } );
        if ( row.description.length > 0 ) this.setState ( {descriptionValid: true } );
      }
    }

  }

  handleEditClick ( ) {
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });

    
    //this.setState({ saveButtonVisible: cEdit });
  }

  getImage ( newImage ) {
    if ( newImage ) {
      if ( this.state.row ) {
        let nRow = this.state.row;
        nRow.image = newImage;
        this.setState ( { row: nRow }, this.setState ( { somethingHasChanged: true } ) );
      }
    }
  }

  getImage_off ( newImage ) {
    if ( newImage ) {
      if ( this.state.row ) {
        let nRow = this.state.row;
        nRow.image_off = newImage;
        this.setState ( { row: nRow }, this.setState ( { somethingHasChanged: true } ) );
      }
    }
  }

  changeValue ( e ) {
    let id = null;
    if ( e.target.id !== null ) id = e.target.id;
    let value = null;
    if  ( e.target.value !== null ) value = e.target.value;
    //if ( id !== null && value !== null && value !== undefined ) {
    if ( id ) {
      let row = {};
      if ( this.state.row ) row = this.state.row;
      switch ( id ) {
        case "name":
          if ( value.length > 0 ) {
            row.name = value;
            this.setState ( { nameValid: true });
          } else {
            row.name = "";
            this.setState ( { nameValid: false });
          }
          this.setState ( { somethingHasChanged: true } );
          break;
        case "description":
          if ( value.length > 0 ) {
            row.description = value;
            this.setState ( { descriptionValid: true });
          } else {
            row.description = "";
            this.setState ( { descriptionValid: false });
          }
          this.setState ( { somethingHasChanged: true } );
          break;
      }
      this.setState ( { row: row });
    }
  }

  pushData ( e ) {
    if ( this.state.somethingHasChanged && this.state.nameValid && this.state.descriptionValid ) {
      let rdata = this.state.client.pushData ( Config.API_URL_PRODUCT_CLASS_ADD, this.state.row );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {
          let uCategory = this.state.row;
          uCategory.iref = rdata.iref;
          this.setState ( { row: uCategory } );
          this.handleEditClick ( );
          swal ( "Success", "Successfully added/modified category ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            //this.props.personUpdated ( );
            this.setState ( {edit: false} );
            this.setState ( { somethingHasChanged: false } );
            this.props.classAdded ();
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Error", "Add/Update category failed !\n\n" + rdata.message, "error" );
        }
      }
    } else {
      swal ( "Error", "Add/Update category failed ! Some fields are not validated !", "error" );
    }
  }

  render() {
    let row = {};
    let iref = "0";
    let name = "";
    let description = "";
    let image = noImage;
    let image_off = noImage;
    let productsCounter = 0;
    if ( this.state.row ) {
      row = this.state.row;
      if ( row.iref ) iref = row.iref;
      if ( row.name ) name = row.name;
      if ( row.description ) description = row.description;
      if ( row.image ) image = row.image;
      if ( row.image_off ) image_off = row.image_off;
      if ( row.description ) description = row.description;
      if (row.productsCounter) productsCounter = row.productsCounter;
    }
    if ( this.props.dataRow !== null && this.props.dataRow != undefined ) {
      row = this.props.dataRow;
    }

    let kImage = {
      data: image,
      height: 128,
      width: 128
    };
    let imageName = name + "_on";

    let kImage_off = {
      data: image_off,
      height: 128,
      width: 128
    };
    let imageName_off = name + "_off";
    
    let showSave = false;
    if ( this.state.edit === true && this.state.somethingHasChanged === true && this.state.nameValid === true && this.state.descriptionValid === true )
      showSave = true;

    console.log (kImage);

    return (
      <StyledTableRow key={row.iref}>
        <StyledTableCell align="left">
          {iref}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {this.state.edit ? 
          <TextField
            id="name"
            label="Name"
            variant="outlined"
            value={name || ''}
            onChange={this.changeValue}
            onFocus={event => { event.target.select(); }}
            inputProps={{maxLength:"32"}}
            error={this.state.nameValid === false}
            required={true}
            sx={{minWidth: "100px"}}
          />
          : name }
        </StyledTableCell>
        
        <StyledTableCell align="left">
          {this.state.edit ? 
            <TextField
              id="description"
              label="Description"
              variant="outlined"
              value={description || ''}
              onChange={this.changeValue}
              onFocus={event => { event.target.select(); }}
              inputProps={{maxLength:"64"}}
              error={this.state.descriptionValid === false}
              required={true}
            />
            :description}
          </StyledTableCell>

        <StyledTableCell align="left">
          <KImage
            edit = {this.state.edit}
            image = {kImage}
            imageText = {imageName}
            saveBack = {this.getImage}
           />
        </StyledTableCell>
        <StyledTableCell align="left">
          <KImage
              edit = {this.state.edit}
              image = {kImage_off}
              imageText = {imageName_off}
              saveBack = {this.getImage_off}
           />
        </StyledTableCell>
        <StyledTableCell align="left">
          {productsCounter}
        </StyledTableCell>
        <StyledTableCell align="right">
          <IconButton aria-label="settings" onClick={this.handleEditClick}>
            <EditIcon
              sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}              
            />
        </IconButton>
        {showSave ?
          <IconButton aria-label="settings" onClick={this.pushData}>
            <SaveIcon
              sx={{ color: "#4287f5" }}            
            />
      </IconButton>
        : ""}
        </StyledTableCell>
      </StyledTableRow>
    );
  }
}


class Classes extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      productClasses: null,
      edit: false,
      addClass: false,
      //addNewProduct: false,
    };

    this.classAdded = this.classAdded.bind(this);
    this.toggleAdd = this.toggleAdd.bind(this);
    this.getData = this.getData.bind(this);

  }

  getData ( ) {
    let queryData = {}
    queryData [ "orderBy" ] = "name";
    let productsClasses = this.state.client.getProductsClasses ( queryData );
    this.setClasses ( productsClasses.productClasses );
  }

  componentDidMount() {    
    this.getData ( );    
  }

  componentWillUnmount() {    
  }

  setClasses ( classes ) {
    let a = { }
    if ( classes !== null && classes !== undefined ) {
      classes.map ( (productClass) => {
        a [ productClass.iref ] = productClass;        
      });
      this.setState ( { productClasses : a });
    }
  }

  classAdded ( ) {
    this.getData ( );
    this.setState ( { addClass: false } );
  }

  toggleAdd ( ) {
    let addButton = this.state.addClass;
    this.setState ( {addClass: !addButton} );
    //this.props.toggleAdd ( !addButton );
  }
  
  render() {
    console.log (this.state.productClasses);
    let productClasses = [];
    if ( this.state.productClasses !== null && this.state.productClasses !== undefined ) {
      productClasses = Object.values ( this.state.productClasses );
    }

    let allData ="";
    if ( productClasses ) {
      allData = productClasses.map((row) => {
        return <DataRow
          key = {row.iref}
          row = {row}
          classAdded={this.classAdded}
        />
      });
    }

    return (

      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <IconButton aria-label="Add Location" title="Add Location" onClick={() => this.toggleAdd()}>       
                <Avatar alt="Add Location" sx={{ width: 24, height: 24, color: "#273442" }}>
                  {this.state.addClass ? <FaMinusCircle/> : <FaPlusCircle/>}
                </Avatar>
              </IconButton>
            </StyledTableCell>
            <StyledTableCell>
              Class Name
            </StyledTableCell>
            <StyledTableCell align="left">Description</StyledTableCell>
            <StyledTableCell align="left">Image (On)</StyledTableCell>
            <StyledTableCell align="left">Image (Off)</StyledTableCell>
            <StyledTableCell align="left">Products</StyledTableCell>
            <StyledTableCell align="right">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.addClass ?
          <DataRow key={0} edit={true} classAdded={this.classAdded}/>
          : ""
          }
          {allData}
        </TableBody>
      </Table>
    </TableContainer>
      

    );

  }
}

export default Classes;
