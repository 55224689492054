import React from 'react';
import { Avatar, Box, Card, CardActions, CardContent, CardHeader, Divider, Button, Paper, TableContainer, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import IconButton from '@mui/material/IconButton';
import { SeverityPill } from './severity-pill';
import { format, intervalToDuration } from 'date-fns';
import Client from '../Client';
import Config from '../ClientConfig';

function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

let d = new Date();
let today = format(d, 'yyyy-MM-dd');  // mm=week, MM=month
let yesterday = format(d.setDate(d.getDate() - 1), 'yyyy-MM-dd');
let d1Month = format(new Date(d.getFullYear(), d.getMonth(), 1), 'yyyy-MM-dd'); //beginning of the month
let dzMonth = format(new Date(d.getFullYear(), d.getMonth() + 1, 0), 'yyyy-MM-dd'); //end of the month
let d1Year = format(new Date(d.getFullYear(), 0, 1), 'yyyy-MM-dd'); //beginning of the year
let dzYear = format(new Date(d.getFullYear(), 11, 31), 'yyyy-MM-dd'); //end of the year
let d1Week = format(d.setDate(d.getDate() - d.getDay() + 1), 'yyyy-MM-dd'); //beginning of the week
let dzWeek = format(d.setDate(d.getDate() - d.getDay() + 7), 'yyyy-MM-dd'); //end of the week
let d1LWeek = format(d.setDate(d.getDate() - d.getDay() - 13), 'yyyy-MM-dd'); //beginning of last week
let dzLWeek = format(d.setDate(d.getDate() - d.getDay() + 7), 'yyyy-MM-dd'); //end of last week
// console.log(today);  
class SalesYTDProductList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      salesproduct_list: [],
      page: 0,
      rowsPerPage: 10,
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    // today = '2024-02-25'
    let queryData = { 
      "type": 5,
      "dateFrom": today,
      "dateTo": today
  }
	  let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { salesproduct_list: data });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    this.getData ( );
  }

  handleChangePage = (event, newPage) => {
    this.setState ({page: newPage});
  }
  
  handleChangeRowsPerPage = (event) => {
    this.setState ({rowsPerPage: +event.target.value});
    this.setState ({page: 0});
  }

  render() {
    let page = this.state.page;
    let rowsPerPage = this.state.rowsPerPage;
    let productSalesData = [];
		let productSalesList = [];
		if (this.state.salesproduct_list){
			if (this.state.salesproduct_list.result) {
				let counter = 0;
				Object.keys(this.state.salesproduct_list.result).forEach(key => {
					let fData = this.state.salesproduct_list.result [ key ];
					let myData = {'x': fData.name, 'y': (parseInt(fData.value))};
					let p = {
						id: counter+1,
						name: fData.name,
						quantity: thousandsSeparator(fData.quantity),
						value: thousandsSeparator(parseInt(fData.value))
					}
					if (counter<10){
						productSalesData.push ( myData );
					}
					counter += 1;
					productSalesList.push ( p );
				});
			}
    }

    return (
      <Paper sx={{ width: '100%', borderRadius:"16px", overflow: 'hidden' }}>
      <Card style={{ height: '500px' }} sx={{ borderRadius:"16px", height: '100%' }}>
        <CardHeader title="Sales Today" />
          <Box>
            <TableContainer sx={{ height: '380px' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell style={{padding: "6px", textAlign: "center" }}>
                    <b>Top</b>
                  </TableCell>
                  <TableCell style={{padding: "6px", textAlign: "left" }}>
                    <b>Name</b>
                  </TableCell>
                  <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                    <b>Quantity</b>
                  </TableCell>
                  <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                    <b>Value</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productSalesList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product) => {
                  return (
                    <TableRow
                      hover
                      key={product.id}
                      tabIndex={-1}
                    >
                      <TableCell style={{padding: "6px", textAlign: "center" }}>
                        {product.id}
                      </TableCell>
                      <TableCell style={{padding: "6px", textAlign: "left" }}>
                        {product.name}
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                          {product.quantity}
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                          {product.value}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, { value: productSalesList.length, label: 'All' }]}
              labelRowsPerPage="Rows:"
              // showFirstButton="True"
              // showLastButton="True"
              component="div"
              count={productSalesList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </Box>
      </Card>
      </Paper>
    );

  }
}

export default SalesYTDProductList;
