import React from 'react';
import { alpha, Avatar, createTheme, AspectRatio, CardOverflow, Box, Card, Grid, CardActions, CardContent, CardHeader, Divider, Stack, SvgIcon, Typography, Button, TextField, Menu, MenuItem, Select, Table, TableContainer, TableBody, TableCell, TableHead, TablePagination, TableRow, Checkbox, ListItemText } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import ViewListIcon from '@mui/icons-material/ViewList';
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import DropDown from '../DropDown';
import { format, intervalToDuration } from 'date-fns';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import Chart from './chart';

import Client from '../Client';
import Config from '../ClientConfig';
import BackOfficeConfig from '../../Config';

let theme = createTheme();

const objectToCsv = function (data) {
  const csvRows = [];
  /* Get headers as every csv data format 
  has header (head means column name)
  so objects key is nothing but column name 
  for csv data using Object.key() function.
  We fetch key of object as column name for 
  csv */
  const headers = Object.keys(data[0]);
  /* Using push() method we push fetched 
     data into csvRows[] array */
  csvRows.push(headers.join(','));
  // Loop to get value of each objects key
  for (const row of data) {
      const values = headers.map(header => {
          const val = row[header]
          return `"${val}"`;
      });
      // To add, separator between each value
      csvRows.push(values.join(','));
  }
  /* To add new line for each objects values
     and this return statement array csvRows
     to this function.*/
  return csvRows.join('\n');
};

function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

function generateYearsBetween(startYear = 2000, endYear) {
  const endDate = endYear || new Date().getFullYear();
  let years = [];
  while (startYear <= endDate) {
    years.push(startYear);
    startYear++;
  }
  return years;
}

let end = 0;
let start = 0;

end = new Date().getFullYear();
start = end - 3;
const yearsArray = generateYearsBetween(start, end);
yearsArray.reverse();
// console.log(yearsArray);
let years ={}
years = yearsArray.map(x => ({value: x}));
// console.log(years);  
const now0 = new Date();
now0.setHours(0,0,0,0);
/* console.log(now0); */
const now = now0;
const csvdate = format(now, 'yyyy-MM-dd'); 

class OverviewProductSales_multiple extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      location: [0],
      product: [0],
      year: new Date().getFullYear(),
      value: "1",
      locations: [{'iref': 0, 'name': "All Locations"}],
      products: [{'iref': 0, 'name': "All Products"}],
      filterData: {},
      sales_month: []
    };

    this.pushData = this.pushData.bind(this);
    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectYear = this.selectYear.bind(this);
    this.selectLocation = this.selectLocation.bind(this);
    this.selectProduct = this.selectProduct.bind(this);

    this.queryData = { };
  }

  componentDidMount() {
    this.getData ();
    this.pushData ();
  }

  getData ( ) {  
    let data = { 
      "location_status": 1,
      "isForSale": 1,  
      "orderBy_name": true      // order products by name
    }

    let locationData = {
      "type": 25,
    }
    let productData = {
      "type": 26,
    }
    this.setState ( { filterData: data } );

    // let locations = this.state.client.getData ( Config.API_URL_LOCATIONS_SIMPLE, data );
    let locations = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, locationData );
    this.setState ( { locations: locations.locations });

    // let products = this.state.client.getData ( Config.API_URL_PRODUCT, data );\
    let products = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, productData );
    this.setState ( { products: products.products });
  }

  pushData ( ) {  
    let queryData = { 
      "type": 1,
      year: this.state.year,
      location: this.state.location,
      location_status: 1,
      product: this.state.product,
      // "product_status": 2,
      // "orderBy_name": true      // order products by name
    }
    let data = this.state.client.pushData ( Config.API_URL_REPORTS_FINANCIAL_SALES_POST, queryData );
    if ( data ) {
      this.setState ( { sales_month: data });
    }
  }


  doSync ( ) {
    this.pushData ();
  }

  selectYear = (event) => {
    this.setState (
      {year: event.target.value},
      function(){
        this.doSync ( );
      }
      );
  }
  
  selectLocation = (event) => {
      this.setState (
        {location: event.target.value},
        function(){
          if (this.state.location[0] == 0) {
            // console.log('location starts ALL');
            delete this.state.location[0];
            // console.log(this.state.location);
          } 
          // console.log(this.state.location.slice(-1));
          if (this.state.location.slice(-1) == 0) {
            // console.log('location contains ALL');
            this.setState({location:[]});
            this.setState({location:[0]});
          }
          this.doSync ( );
          
        }
        );
  }

  selectProduct = (event) => {
    this.setState (
      {product: event.target.value},
      function(){
        if (this.state.product[0] == 0) {
          // console.log('product starts ALL');
          delete this.state.product[0];
          // console.log(this.state.product);
        } 
        // console.log(this.state.product.slice(-1));
        if (this.state.product.slice(-1) == 0) {
          // console.log('product contains ALL');
          this.setState({product:[]});
          this.setState({product:[0]});
        }
        this.doSync ( );
        
      }
      );
}

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  }

  onDownload = () => {
    const link = document.createElement("a");
    let csvData = 0
    link.download = "salesProductsOverview_" + csvdate + ".csv";
    // console.log(this.state.saleslocation_list);
    // console.log(this.state.saleslocation_list.result);
    if ( this.state.sales_month ) {
      csvData = objectToCsv(this.state.sales_month.sales);
    }
    let csvHeader = "Sales Products Overview"+ "\n" + "Locations: "+ this.state.location + "\n" + "Products: " + this.state.product;
    let csv = csvHeader + "\n" + csvData;
    link.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);

    // console.log(link);
    link.click();
  };


  render() {

    let locations = [{'iref': 0, 'name': "All Locations"}];
    if (this.state.locations){
      Object.keys(this.state.locations).forEach(key => {
        let fData = this.state.locations [ key ];
        let myData = {'iref': fData.iref, 'name': fData.name};
        locations.push ( myData );
      });
    }
  
    let products = [{'iref': 0, 'name': "All Products"}];
    if (this.state.products){
      Object.keys(this.state.products).forEach(key => {
        let fData = this.state.products [ key ];
        let myData = {'iref': fData.iref, 'name': fData.name};
        products.push ( myData );
      });
    }

    let sales = [];
    let salesT = [];
    let salesA = [];
    let salesP = [];
    let salesD = [];
    let salesD_LY = [];
    let salesM_new = [];
		let salesM_LY_new = [];
    let salesW = [];
		let salesW_LY = [];

    if ( this.state.sales_month.length !== 0 ) {
        let sales_month = this.state.sales_month;
        if (this.state.sales_month.sales) {
          Object.keys(this.state.sales_month.sales).forEach(key => {
            let fData = this.state.sales_month.sales [ key ];
            let sign ="";
            if (fData.month < 10) {sign = "-0"} else {sign = "-"};
            let myData = {'x': fData.year+sign+fData.month, 'y': fData.total};
            sales.push ( myData );
            let myTrans = {'x': fData.year+sign+fData.month, 'y': fData.quantity};
            salesT.push ( myTrans );
            let myAvg = {'x': fData.year+sign+fData.month, 'y': fData.total/fData.quantity};
            salesA.push ( myAvg );

            let tzx = fData.total/fData.tot*100;
            tzx = Math.round(tzx);
            if (tzx < 0) tzx = 0;
            if (tzx > 100) tzx = 100;
            //let myPct = {'x': fData.year+sign+fData.month, 'y': fData.total/fData.tot*100};
            let myPct = {'x': fData.year+sign+fData.month, 'y': tzx};
            salesP.push ( myPct );
          });
        } 
        // console.log(...Object.entries(sales).flat());
        if (this.state.sales_month.salesD) {
          let days = "";
          Object.keys(this.state.sales_month.salesD).forEach(key => {
            days = BackOfficeConfig.WEEKDAYS_EN [ parseInt(key)+1 ]
            let fData = this.state.sales_month.salesD [ key ];
            let myData = {'x': days, 'y': fData.total/fData.count};
            salesD.push ( myData );
          });
        }
        if (this.state.sales_month.salesD_LY) {
          let days = "";
          Object.keys(this.state.sales_month.salesD_LY).forEach(key => {
            days = BackOfficeConfig.WEEKDAYS_EN [ parseInt(key)+1 ]
            let fData = this.state.sales_month.salesD_LY [ key ];
            let myData = {'x': days, 'y': fData.total/fData.count};
            salesD_LY.push ( myData );
          });
        }  

        if (this.state.sales_month.salesM_new) {
          Object.keys(this.state.sales_month.salesM_new).forEach(key => {
            let fData = this.state.sales_month.salesM_new [ key ];
            let myData = {'x': fData.month, 'y': fData.total};
            salesM_new.push ( myData );
          });
        } 
        if (this.state.sales_month.salesM_LY_new) {
          Object.keys(this.state.sales_month.salesM_LY_new).forEach(key => {
            let fData = this.state.sales_month.salesM_LY_new [ key ];
            let myData = {'x': fData.month, 'y': fData.total};
            salesM_LY_new.push ( myData );
          });
        } 

        if (this.state.sales_month.salesW) {
          Object.keys(this.state.sales_month.salesW).forEach(key => {
            let fData = this.state.sales_month.salesW [ key ];
            let myData = {'x': fData.week, 'y': fData.total};
            salesW.push ( myData );
          });
        } 
        if (this.state.sales_month.salesW_LY) {
          Object.keys(this.state.sales_month.salesW_LY).forEach(key => {
            let fData = this.state.sales_month.salesW_LY [ key ];
            let myData = {'x': fData.week, 'y': fData.total};
            salesW_LY.push ( myData );
          });
        } 
    }

    let maxM_LY_new = Math.max(...salesM_LY_new.map(({ y }) => y));
    let maxM_new = Math.max(...salesM_new.map(({ y }) => y));
    let maxM = parseInt(Math.max(maxM_LY_new,maxM_new));
    let p1 = maxM.toString().length -1;
    maxM = Math.ceil(maxM/10**p1 )*10**p1;
   
    let maxW_LY = Math.max(...salesW_LY.map(({ y }) => y));
    let maxW = Math.max(...salesW.map(({ y }) => y));
    // let maxW_LY_trans = Math.max(...salesW_LY_trans.map(({ y }) => y));
    // let maxW_trans = Math.max(...salesW_trans.map(({ y }) => y));
    let maxWm = parseInt(Math.max(maxW_LY,maxW));
    let p3 = maxWm.toString().length -1;
    maxWm = Math.ceil(maxWm/10**p3 )*10**p3;
    // let maxWm_trans = parseInt(Math.max(maxW_LY_trans,maxW_trans));
    // let p4 = maxWm_trans.toString().length -1;
    // maxWm_trans = Math.ceil(maxWm_trans/10**p4 )*10**p4;

    return (
        <Card variant="outlined" style={{ height: '600px' }} sx={{ paddingTop:"6px", borderRadius:"16px", height: '100%' }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between'}}>
              <Box sx={{ padding: '16px', alignItems: 'baseline' }}>
              <TextField
                id="standard-select-years"
                select
                // label="Select"
                defaultValue={end}
                // helperText="Select Years"
                variant="standard"
                sx={{ width: 100}}
                onChange={this.selectYear}
              >
                  {years.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              <Select
                id="standard-select-location"
                multiple
                // label="Select"
                value={this.state.location}
                // defaultValue="0"
                // helperText="Select Location"
                variant="standard"
                sx={{ width: 180, maxHeight: 250}}
                onChange={this.selectLocation}
              >
                  {locations.map((option) => (
                  <MenuItem key={option.iref} value={option.iref}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              <Select
                id="standard-select-product"
                multiple
                // label="Select"
                value={this.state.product}
                // defaultValue="0"
                // helperText="Select Product"
                variant="standard"
                sx={{ width: 180, maxHeight: 250}}
                onChange={this.selectProduct}
              >
                  {products.map((option) => (
                  <MenuItem key={option.iref} value={option.iref}>
                    {/* <Checkbox checked={option.name.indexOf(option.iref) > -1} /> */}
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              </Box >
              <Tabs value={this.state.value} onChange={this.handleChange} aria-label="tabs example">
                <Tab style= {{}} icon={<BarChartIcon />} value="1" />
                <Tab classes={{ tab: {width: 50}, tabRoot: {width: 50}, root: {width: 50} }} label="Month" value="2" />
                <Tab label="Week" value="3" />
                <Tab label="wDay" value="4" />
              </Tabs>  
          </Box>
        <Box>
            {this.state.value === "1" && 
	     sales.length !== 0 && salesT.length !== 0 &&
	     salesA !== 0 && salesP !== 0 ? 
                  <Chart
                  height={350}
                  type='line0'
                  series={[
                    {
                    name: 'Monthly sales',
                    type: 'bar',
                    data: sales
                    },
                    {
                      name: 'Monthly transactions',
                      type: 'line',
                      data: salesT
                    },
                    {
                      name: 'Average transaction',
                      type: 'line',
                      data: salesA
                    },
                    {
                      name: 'Percentage',
                      type: 'line',
                      data: salesP
                    }
                  ]}
                  width={"100%"}
                />
		    : ""
            }
            {this.state.value === "2" ?
              <Chart
              height={350}
              type="line11"
              options={{
                chart: {
                  // type: 'bar',
                  background: 'transparent',
                  stacked: false,
                  toolbar: {
                    show: false,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                      download: true,
                      selection: true,
                      zoom: true,
                      zoomin: true,
                      zoomout: true,
                      pan: true,
                      reset: true | '<img src="/static/icons/reset.png" width="20">',
                      customIcons: []
                    }
                  },
                  export: {
                    csv: {
                      filename: undefined,
                      columnDelimiter: ',',
                      headerCategory: 'category',
                      headerValue: 'value',
                      dateFormatter(timestamp) {
                        return new Date(timestamp).toDateString()
                      }
                    },
                    svg: {
                      filename: undefined,
                    },
                    png: {
                      filename: undefined,
                    }
                  },
                  zoom: {
                    enabled: true,
                    type: 'x',
                    autoScaleYaxis: false,
                    zoomedArea: {
                      fill: {
                        color: '#90CAF9',
                        opacity: 0.4
                      },
                      stroke: {
                        color: '#0D47A1',
                        opacity: 0.4,
                        width: 1
                      }
                    }
                  }
                },
                colors: [alpha(theme.palette.primary.main, 0.25), theme.palette.primary.main],
                dataLabels: {
                  enabled: false
                },
                fill: {
                  opacity: 1,
                  type: 'solid'
                },
                grid: {
                  borderColor: theme.palette.divider,
                  strokeDashArray: 2,
                  xaxis: {
                    lines: {
                      show: false
                    }
                  },
                  yaxis: {
                    lines: {
                      show: true
                    }
                  }
                },
                legend: {
                  show: false
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: '70%',
                    barHeight: '70%',
                    distributed: false,
                    colors: {
                      backgroundBarColors: Array
                    }
                  }
                },
                stroke: {
                  colors: [alpha(theme.palette.primary.main, 0.25), theme.palette.primary.main],
                  show: true,
                  curve: 'smooth', // 'smooth', 'straight', 'stepline'
                  lineCap: 'square', // 'butt', 'square', 'round'
                  width: 2
                },
                theme: {
                  mode: theme.palette.mode
                },
                xaxis: {
                    tickPlacement: 'on',
                    axisBorder: {
                      color: theme.palette.divider,
                      show: true
                    },
                    axisTicks: {
                      color: theme.palette.divider,
                      show: true
                    },
                    labels: {
                      offsetY: 5,
                      style: {
                        colors: theme.palette.text.secondary
                      }
                    }
                },
                markers: {
                  size: [0, 0, 3, 3]
                },              
                yaxis: [
                  {
                    seriesName: 'Last year Value',
                    show: true,
                    min: 0,
                    max: maxM,
                    labels: {
                      formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
                      offsetX: -10,
                    }
                  },
                  {
                    seriesName: 'This year Value',
                    show: false,
                    min: 0,
                    max: maxM,
                    labels: {
                    formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
                    offsetX: -10,
                    style: {
                      colors: theme.palette.text.secondary
                    }
                  }
                  }
                ]
              }}
              series={[
                {	
                  name: 'Last year Value',
                  data: salesM_LY_new,
                  type: 'bar'
                },
                {	
                  name: 'This year Value',
                  data: salesM_new,
                  type: 'bar'
                }
              ]}
              width="100%"
            />
            : ""
          }
          {this.state.value === "3" ?
            <Box>
              <Chart
              height={350}
              type="line11"
              options={{
                chart: {
                  // type: 'bar',
                  background: 'transparent',
                  stacked: false,
                  toolbar: {
                    show: false,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                      download: true,
                      selection: true,
                      zoom: true,
                      zoomin: true,
                      zoomout: true,
                      pan: true,
                      reset: true | '<img src="/static/icons/reset.png" width="20">',
                      customIcons: []
                    }
                  },
                  export: {
                    csv: {
                      filename: undefined,
                      columnDelimiter: ',',
                      headerCategory: 'category',
                      headerValue: 'value',
                      dateFormatter(timestamp) {
                        return new Date(timestamp).toDateString()
                      }
                    },
                    svg: {
                      filename: undefined,
                    },
                    png: {
                      filename: undefined,
                    }
                  },
                  zoom: {
                    enabled: true,
                    type: 'x',
                    autoScaleYaxis: false,
                    zoomedArea: {
                      fill: {
                        color: '#90CAF9',
                        opacity: 0.4
                      },
                      stroke: {
                        color: '#0D47A1',
                        opacity: 0.4,
                        width: 1
                      }
                    }
                  }
                },
                colors: [alpha(theme.palette.primary.main, 0.25), theme.palette.primary.main],
                dataLabels: {
                  enabled: false
                },
                fill: {
                  opacity: 1,
                  type: 'solid'
                },
                grid: {
                  borderColor: theme.palette.divider,
                  strokeDashArray: 2,
                  xaxis: {
                    lines: {
                      show: false
                    }
                  },
                  yaxis: {
                    lines: {
                      show: true
                    }
                  }
                },
                legend: {
                  show: false
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: '70%',
                    barHeight: '70%',
                    distributed: false,
                    colors: {
                      backgroundBarColors: Array
                    }
                  }
                },
                stroke: {
                  colors: [alpha(theme.palette.primary.main, 0.25), theme.palette.primary.main],
                  show: true,
                  curve: 'smooth', // 'smooth', 'straight', 'stepline'
                  lineCap: 'square', // 'butt', 'square', 'round'
                  width: 2
                },
                theme: {
                  mode: theme.palette.mode
                },
                xaxis: {
                    tickPlacement: 'on',
                    axisBorder: {
                      color: theme.palette.divider,
                      show: true
                    },
                    axisTicks: {
                      color: theme.palette.divider,
                      show: true
                    },
                    labels: {
                      offsetY: 5,
                      style: {
                        colors: theme.palette.text.secondary
                      }
                    }
                },
                markers: {
                  size: [0, 0, 3, 3]
                },              
                yaxis: [
                  {
                    seriesName: 'Last year Value',
                    show: true,
                    min: 0,
                    max: maxWm,
                    labels: {
                      formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
                      offsetX: -10,
                    }
                  },
                  {
                    seriesName: 'This year Value',
                    show: false,
                    min: 0,
                    max: maxWm,
                    labels: {
                    formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
                    offsetX: -10,
                    style: {
                      colors: theme.palette.text.secondary
                    }
                  }
                  }
                ]
              }}
              series={[
                {	
                  name: 'Last year Value',
                  data: salesW_LY,
                  type: 'bar'
                },
                {	
                  name: 'This year Value',
                  data: salesW,
                  type: 'bar'
                }
              ]}
              width="100%"
            />
            </Box>
            : ""
          }
          {this.state.value === "4" ?
              <Chart
                height={350}
                type='bar1'
                series={[
                  {
                    name: 'Daily sales Last Year',
                    data: salesD_LY
                    },
                  {
                  name: 'Daily sales This Year',
                  data: salesD
                  }
                ]}
                width={"100%"}
              />
              : ""
            }          
        </Box>  
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            color="inherit"
            endIcon={(
              <SvgIcon fontSize="small">
                <ArrowForwardIcon />
              </SvgIcon>
            )}
            size="small"
            onClick={this.onDownload} 
            variant="contained" 
            // color="primary"
          >
            Download
          </Button>
        </CardActions>
      </Card>
    );

  }
}

export default OverviewProductSales_multiple;
