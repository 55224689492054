import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import OverviewTotalSales from './components/biReports/OverviewTotalSales';
import OverviewTopSales from './components/biReports/OverviewTopSales';
import OverviewMonthSales from './components/biReports/OverviewMonthSales';
import OverviewProductsProgress from './components/biReports/OverviewProductsProgress';
import OverviewTotalTransactions from './components/biReports/OverviewTotalTransactions';
import OverviewSales from './components/biReports/OverviewSales';
import OverviewSales_multiple from './components/biReports/OverviewSales_multiple';
import SalesYTDLocationChart from './components/biReports/SalesYTDLocationChart';
import SalesYTDPosChart from './components/biReports/SalesYTDPosChart';
import SalesYTDLocationList from './components/biReports/SalesYTDLocationList';
import SalesYTDLocationList_delivery from './components/biReports/SalesYTDLocationList_delivery';
import SalesYTDProductChart from './components/biReports/SalesYTDProductChart';
import SalesYTDProductList from './components/biReports/SalesYTDProductList';
import SalesChartGroup from './components/biReports/SalesChartGroup'
import ProductChartGroup from './components/biReports/ProductChartGroup'
import ProdLocationChartGroup from './components/biReports/ProdLocationChartGroup'
import CategLocationChartGroup from './components/biReports/CategLocationChartGroup';

import Client from './components/Client';
import Config from './components/ClientConfig';


const now = new Date();
class RapSales extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      /* locationGroups: null, */
      edit: false,
    };

 
  }

  componentDidMount() {    
  }

  componentWillUnmount() {    
  }

  /* setLocationsGroups ( groups ) {
    let a = { }
    if ( groups !== null && groups !== undefined ) {
      groups.map ( (locationGroup) => {
        a [ locationGroup.iref ] = locationGroup;        
      });
      this.setState ( { locationGroups : a });
    }
  } */

  
  render() {

		return (
			<Box component="main" sx={{flexGrow: 1, py: 8, paddingTop: "30px", paddingBottom: "30px"}}>
			<Container style={{paddingLeft: 5, paddingRight:5}}  maxWidth="xl">
			  <Grid container spacing={3}>
				<Grid item xs={12} sm={6} lg={3}>
					<OverviewTotalSales />
				</Grid>
				<Grid item xs={12} sm={6} lg={3}>
				  <OverviewTotalTransactions />
				</Grid>
				<Grid item xs={12} sm={6} lg={3}>
					<OverviewMonthSales />
				</Grid>
				<Grid item xs={12} sm={6} lg={3}>
					<OverviewTopSales />
				</Grid>
				<Grid item xs={12} lg={12}>
					<OverviewSales_multiple />
					{/* <OverviewSales /> */}
				</Grid>
				<Grid item xs={12} md={6} lg={6}>
					<SalesYTDLocationList_delivery />
				</Grid>
				<Grid item xs={12} md={6} lg={6}>
					<SalesYTDLocationList />
				</Grid>
				{/* <Grid item xs={12} md={6} lg={6}>
					<SalesChartGroup />
				</Grid> */}				
				{/* <Grid item xs={12} md={6} lg={4}>
					<SalesYTDPosChart />
				</Grid> */}
			  </Grid>
			</Container>
		  </Box>
		);
	}
}

export default RapSales;
