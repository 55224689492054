import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
//import { createTheme , ThemeProvider } from '@material-ui/core/styles';
import Client from './components/Client';
//import isAuthTokenValid from './components/Client';

window.client = new Client();

//const [toggleDark, settoggleDark] = useState(false);
/* const myTheme=createTheme ({
    // Theme settings
    palette:{
      type: 'dark',
    }
}); */

function App() {

	console.log ( "------------------ CARTOS --------------" );
  //const token = localStorage.getItem('accessToken');
  var data = JSON.parse(localStorage.getItem("apiUser"));  
  if (data === null || data.accessToken === undefined || !window.client.isAccessTokenValid(data.accessToken)) {
    return <Login />
  }

  /* if(!token) {
    
    
  } */

  //Get languages
  /* localStorage.setItem("apiUser", JSON.stringify({
    "profile": that.profile,
    "accessToken": that.accessToken,
    "refreshToken": that.refreshToken,
    "time": (new Date()).getTime(),
    })); */
  
  //Get configs (identity/front-end)
  
  //console.log ( JSON.stringify ( cData ) );
  //localStorage.setItem ( "config", JSON.stringify (cData ) );

  window.client.setConfigLocalStorage ( );
  window.client.setLanguagesLocalStorage ( );

  /* let platform = { };
  let identityTexts = { };
  let identityImages = { };
  Object.keys(cData).forEach(key => {
    let fData = cData [ key ];
    switch ( key ) {
      case "platform":
        fData.map ( (d) => {
          platform [ d.key ] = d.value;
        });
        break;

      case "identityTexts":
        fData.map ( (d) => {
          identityTexts [ d.key ] = d.value;
        });
        break;

      case "identityImages":
        fData.map ( (d) => {
          identityImages [ d.key ] = d.image;
        });
        break;
    }     
  });

  let config = { };
  config [ "platform" ] = platform;
  config [ "identityTexts" ] = identityTexts;
  config [ "identityImages" ] = identityImages;

  localStorage.setItem ( "config",  JSON.stringify(config) ); */
  //

  return (

      
          <Router basename={'/backoffice'}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/backoffice" element={<Dashboard />} />
          </Routes>
        </Router>
    
    

  );
}

export default App;
