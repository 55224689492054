import React from 'react';
import { Avatar, createTheme, AspectRatio, CardOverflow, Box, Card, Grid, CardActions, CardContent, CardHeader, Divider, Stack, SvgIcon, Typography, Button, TextField, Menu, MenuItem, Table, TableContainer, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import ViewListIcon from '@mui/icons-material/ViewList';
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import DropDown from '../DropDown';
import { format, intervalToDuration } from 'date-fns';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import Chart from './chart';

import Client from '../Client';
import Config from '../ClientConfig';

let theme = createTheme();

function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

let d = new Date();
let today = format(d, 'yyyy-MM-dd');  // mm=week, MM=month
let yesterday = format(d.setDate(d.getDate() - 1), 'yyyy-MM-dd');
let d1Month = format(new Date(d.getFullYear(), d.getMonth(), 1), 'yyyy-MM-dd'); //beginning of the month
let dzMonth = format(new Date(d.getFullYear(), d.getMonth() + 1, 0), 'yyyy-MM-dd'); //end of the month
let d1Year = format(new Date(d.getFullYear(), 0, 1), 'yyyy-MM-dd'); //beginning of the year
let dzYear = format(new Date(d.getFullYear(), 11, 31), 'yyyy-MM-dd'); //end of the year
let d1Week = format(d.setDate(d.getDate() - d.getDay() + 1), 'yyyy-MM-dd'); //beginning of the week
let dzWeek = format(d.setDate(d.getDate() - d.getDay() + 7), 'yyyy-MM-dd'); //end of the week
let d1LWeek = format(d.setDate(d.getDate() - d.getDay() - 13), 'yyyy-MM-dd'); //beginning of last week
let dzLWeek = format(d.setDate(d.getDate() - d.getDay() + 7), 'yyyy-MM-dd'); //end of last week
// console.log(today);

const period = [
    {
        value: 'today',
        label: 'Today'
    },
    {
      value: 'yesterday',
      label: 'Yesterday'
  },
    /* {
        value: 'week',
        label: 'This Week'
    },
    {
        value: 'month',
        label: 'This Month'
    },
    {
        value: 'year',
        label: 'This Year'
    }, */
    {
        value: 'custom',
        label: 'Custom'
    }
];
  
class ProdLocationChartGroup extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      saleslocation_list: [],
      page: 0,
      rowsPerPage: 10,
      dateFrom: today,
      dateTo: today,
      product: 0,
      sx_hidden: {display: 'none'},
      value: "1",
      products: [{'iref': 0, 'name': "All Products"}],
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addDateFrom = this.addDateFrom.bind(this);
    this.addDateTo = this.addDateTo.bind(this);
    this.selectPeriod = this.selectPeriod.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
    this.queryData = { };
  }

  componentDidMount() {
    // console.log (this.state.dateFrom);
    // console.log (this.state.dateTo);
    this.getData ();
  }

  getData ( ) {  
    let queryData = { 
      "type": 10,
      "product": this.state.product,
      // "product_status": 2,
      "dateFrom": this.state.dateFrom,
      "dateTo": this.state.dateTo,
      // "isForSale": 1,
      "orderBy_name": true      // order products by name
    }
    // console.log(queryData);
	  let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { saleslocation_list: data });
    }
    this.setState ( { filterData: queryData } );

    let productData = {
      "type": 26,
    }
    // let products = this.state.client.getData ( Config.API_URL_PRODUCT, queryData );
    let products = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, productData );
    this.setState ( { products: products.products });
  }
  
  doSync ( ) {
    this.getData ( );
  }

  addDateFrom = (event) => {
    this.setState ({dateFrom: event.target.value});
    this.setState ({dateTo: event.target.value});
  }

  addDateTo = (event) => {
    this.setState ({dateTo: event.target.value});
  }

  selectPeriod = (event) => {
    switch ( event.target.value ) {
      case "today":
        this.setState ({sx_hidden: {display: 'none'}},
        this.setState (
          {dateFrom: today},  //today
          function(){
          }
        ));
        this.setState (
          {dateTo: today},  //today
          function(){
            this.doSync ( );
          }
        );
        break;
      case "yesterday":
        this.setState ({sx_hidden: {display: 'none'}},
        this.setState (
          {dateFrom: yesterday}, //yesterday
          function(){
          }
        ));
        this.setState (
          {dateTo: yesterday}, //yesterday
          function(){
            this.doSync ( );
          }
        );
        break;
      /* case "week":
        this.setState ({sx_hidden: {display: 'none'}}),  
        this.setState (
          {dateFrom: d1Week},  //beginning of the week
          function(){
          }
        );
        this.setState (
          {dateTo: today},  //today
          function(){
            this.doSync ( );
          }
        );
        break;  
      case "month": 
        this.setState ({sx_hidden: {display: 'none'}}),  
        this.setState (
          {dateFrom: d1Month},  //beginning of the month
          function(){
          }
        );
        this.setState (
          {dateTo: today},  //today
          function(){
            this.doSync ( );
          }
        ); 
        break;            
      case "year":
        this.setState ({sx_hidden: {display: 'none'}}),
        this.setState (
          {dateFrom: d1Year},  //beginning of the year
          function(){
          }
        );
        this.setState (
          {dateTo: today},  //today
          function(){
            this.doSync ( );
          }
        );
        break; */            
      default:
        this.setState (
          {sx_hidden: {}},
          function(){
            this.doSync ( );
          }
        );
        // console.log(`No period selected.`);
    }
  }

  selectProduct = (event) => {
      this.setState (
        {product: event.target.value},
        function(){
          this.doSync ( );
        }
        );
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  }

  handleChangePage = (event, newPage) => {
    this.setState ({page: newPage});
  }
  
  handleChangeRowsPerPage = (event) => {
    this.setState ({rowsPerPage: +event.target.value});
    this.setState ({page: 0});
  }

  render() {

    let products = [{'iref': 0, 'name': "All Products"}];
    if (this.state.products){
      Object.keys(this.state.products).forEach(key => {
        let fData = this.state.products [ key ];
        let myData = {'iref': fData.iref, 'name': fData.name};
        products.push ( myData );
      });
  }

    let productSalesData = [];
		let productSalesList = [];
		if (this.state.saleslocation_list){
			if (this.state.saleslocation_list.result) {
				let counter = 0;
				Object.keys(this.state.saleslocation_list.result).forEach(key => {
					let fData = this.state.saleslocation_list.result [ key ];
					let myData = {'x': fData.name, 'y': (parseInt(fData.value))};
					let p = {
						id: counter+1,
						name: fData.name,
						quantity: thousandsSeparator(fData.quantity),
						value: thousandsSeparator(parseInt(fData.value))
					}
					if (counter<10){
						productSalesData.push ( myData );
					}
					counter += 1;
					productSalesList.push ( p );
				});
			}
    }

   /*  let today = new Date();
    let month = parseInt(today.getMonth());
    month += parseInt ( 1 );
    if (month < 10)month = '0' + month;
    if (today.getDate() < 10)get.Date = '0' + today.getDate();
    let currentDate = today.getFullYear() + "-" + month + "-" + today.getDate(); */

    return (
        <Card variant="outlined" style={{ height: '600px' }} sx={{ paddingTop:"6px", borderRadius:"16px", height: '100%' }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between'}}>
            <Box sx={{ padding: '16px', alignItems: 'baseline' }}>
              <TextField
                id="standard-select-period"
                select
                // label="Select"
                defaultValue="today"
                // helperText="Select Period"
                variant="standard"
                sx={{ width: 120}}
                onChange={this.selectPeriod}
              >
                {period.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              &nbsp;
              <TextField
                id="standard-select-product"
                select
                // label="Select"
                defaultValue="0"
                // helperText="Select Location"
                variant="standard"
                sx={{ width: 180}}
                onChange={this.selectProduct}
              >
                  {products.map((option) => (
                  <MenuItem key={option.iref} value={option.iref}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              </Box >
              <Box sx={this.state.sx_hidden} style={{ alignItems: 'baseline', justifyContent: 'space-around'}}>
              <TextField
                size='small'
                id="date"
                label="From"
                type="date"
                // defaultValue={currentDate}
                value={this.state.dateFrom}
                // inputFormat="DD/MM/YYYY"
                sx={{ color: "#e3e3e5", alignSelf: "center", marginLeft: "6px", width:120}}
                InputLabelProps={{
                  shrink: true,
                  sx : { color: "#5b5b5b" }
                }}
                InputProps={{ sx : { color: "#5b5b5b" }}}
                inputProps={{ style : { color: "#5b5b5b", paddingRight: 5, paddingLeft: 5} }}
                onChange={this.addDateFrom}
              />
              {/* <TextField
                size='small'
                id="date"
                label="To"
                type="date"
                // defaultValue={currentDate}
                value={this.state.dateTo}
                sx={{ color: "#e3e3e5", alignSelf: "center", marginLeft: "6px", width:120 }}
                InputLabelProps={{
                  shrink: true,
                  sx : { color: "#5b5b5b" }
                }}
                InputProps={{ sx : { color: "#5b5b5b" }}}
                inputProps={{ style : { color: "#5b5b5b", paddingRight: 5, paddingLeft: 5} }}
                onChange={this.addDateTo}
              /> */}
              <Button color="inherit" onClick={() => this.doSync()} style={{maxWidth: '36px', minWidth: '36px'}}>
                <Avatar sx={{ width: 24, height: 24, bgcolor: theme.palette.primary.light}}> 
                  <AutorenewIcon />
                </Avatar>
              </Button>
              </Box>
              <Tabs value={this.state.value} onChange={this.handleChange} aria-label="tabs example">
                <Tab style= {{}} icon={< ViewListIcon/>} value="1" />
                <Tab classes={{ tab: {width: 50}, tabRoot: {width: 50}, root: {width: 50} }} icon={<TableChartIcon />} value="2" />
                <Tab icon={<ViewListIcon />} value="3" />
              </Tabs>  
          </Box>
        <Box>
            {this.state.value === "1" ?
                <Box>
                <TableContainer sx={{ height: '380px' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{padding: "6px", textAlign: "center" }}>
                        <b>Top</b>
                      </TableCell>
                      <TableCell style={{padding: "6px", textAlign: "left" }}>
                        <b>Name</b>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <b>Quantity</b>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <b>Value</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productSalesList.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((product) => {
                      return (
                        <TableRow
                          hover
                          key={product.id}
                          tabIndex={-1}
                        >
                          <TableCell style={{padding: "6px", textAlign: "center" }}>
                            {product.id}
                          </TableCell>
                          <TableCell style={{padding: "6px", textAlign: "left" }}>
                            {product.name}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {product.quantity}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {product.value}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, { value: productSalesList.length, label: 'All' }]}
                  labelRowsPerPage="Rows:"
                  // showFirstButton="True"
                  // showLastButton="True"
                  component="div"
                  count={productSalesList.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </Box>
                : ""
            }
            {this.state.value === "2" ?
              <Chart
              height={350}
              type="bar2v"
              series={[
                {	
                  name: 'Sales YTD',
                  data: productSalesData
                }
              ]}
              width="100%"
            />
            : ""
          }
          {this.state.value === "3" ?
              <Chart
              height={350}
              type="bar2"
              series={[
                {	
                  name: 'Sales YTD',
                  data: productSalesData
                }
              ]}
              width="100%"
            />
            : ""
          }          
        </Box>  
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            color="inherit"
            endIcon={(
              <SvgIcon fontSize="small">
                <ArrowForwardIcon />
              </SvgIcon>
            )}
            size="small"
          >
            Overview
          </Button>
        </CardActions>
      </Card>
    );

  }
}

export default ProdLocationChartGroup;
