import React from 'react';
import { Avatar, Card, CardContent, CardHeader, Stack, SvgIcon, Typography, Button } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import IconButton from '@mui/material/IconButton';
import MainCard from './MainCard';

import Client from '../Client';
import Config from '../ClientConfig';

function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

const formatDate = (dateStr) => {
  const [year, month, day] = dateStr.split('-');
  let newDate = `${day}-${month}-${year}`;
  return newDate;
};

function formatedDate(value) {
  let date = new Date(value);
  const day = date.toLocaleString('default', { day: '2-digit' });
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.toLocaleString('default', { year: 'numeric' });
  return day + '-' + month + '-' + year;
}

class OverviewTopSales extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      sales_top: [],
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    let queryData = { "type": 2 }
	let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { sales_top: data });
    }
    this.setState ( { filterData: queryData } );
  }


  doSync ( ) {
    this.getData ( );
  }

  render() {
    let topSales = 0;
    let recordDate = "";
    // console.log(this.state.sales_top);

    if ( this.state.sales_top ) {
        let sales_top = this.state.sales_top;
        if ( sales_top.recordDate !== undefined ) recordDate = sales_top.recordDate;
        if ( sales_top.recordSales !== undefined ) topSales = parseInt(sales_top.recordSales);
    }

    return (
      <Card sx={{ overflow: 'hidden', position: 'relative', borderRadius:"16px", height: '100%' }}>
        <CardContent>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Stack spacing={1}>
              <Typography
                sx={{lineHeight: 1.5}}
                color="#1a237e"
                variant="overline"
              >
                SALES RECORD
              </Typography>
              <Typography variant="h4">{thousandsSeparator((topSales/1000).toFixed(1))+"K"}</Typography>
            </Stack>
            {/* <Avatar
              sx={{
                backgroundColor: 'success.main',
                height: 56,
                width: 56
              }}
            >
              <SvgIcon>
              <EmojiEventsIcon />
              </SvgIcon>
            </Avatar> */}
          </Stack>
          {recordDate && (
            <Stack
              alignItems="center"
              justifyContent="center"
              direction="row"
              spacing={2}
              sx={{ mt: 2 }}
            >
              <Stack
                alignItems="center"
                direction="row"
                spacing={0.5}
              >
                <Typography
                  variant="body2"
                >
                  {formatedDate(recordDate)}
                </Typography>
              </Stack>
              <Typography
                color="text.secondary"
                variant="caption"
              >
                record date
              </Typography>
            </Stack>
          )}
        </CardContent>
      </Card>
    );

  }
}

export default OverviewTopSales;


 {/* <Button
                color="inherit"
                size="small"
                startIcon={(
                <SvgIcon fontSize="small">
                    <AutorenewIcon />
                </SvgIcon>
                )}
                onClick={() => this.doSync()}
            /> */}