import React from 'react';
import {Box, createTheme, Card, Paper, Avatar, Typography, TextField, Button} from '@mui/material';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import OverviewTotalSales from './components/biReports/OverviewTotalSales';
import OverviewTopSales from './components/biReports/OverviewTopSales';
import OverviewMonthSales from './components/biReports/OverviewMonthSales';
import OverviewMonthCustomers from './components/biReports/OverviewMonthCustomers';
import OverviewProductsProgress from './components/biReports/OverviewProductsProgress';
import OverviewTotalCustomers from './components/biReports/OverviewTotalCustomers';
import OverviewTotalTransactions from './components/biReports/OverviewTotalTransactions';
import OverviewSales from './components/biReports/OverviewSales';
import OverviewDemographic from './components/biReports/OverviewDemographic';
import OverviewLatestCustomers from './components/biReports/OverviewLatestCustomers';
import OverviewLatestProducts from './components/biReports/OverviewLatestProducts';
import SalesTopList from './components/biReports/SalesTopList';
import SalesPeriodLocationList from './components/biReports/SalesPeriodLocationList';
import SalesTransPeriodLocationList from './components/biReports/SalesTransPeriodLocationList';
import Client from './components/Client';
import Config from './components/ClientConfig';
import PeopleIcon from '@mui/icons-material/People';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { format, intervalToDuration } from 'date-fns';
import IconButton from '@mui/material/IconButton';
import AutorenewIcon from '@mui/icons-material/Autorenew';

let theme = createTheme();
const now = new Date();
let d = new Date();
let today = format(d, 'yyyy-MM-dd');  // mm=week, MM=month

class RapSales2 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      /* locationGroups: null, */
      edit: false,
	  date: today,
	  tabValue: "value"
    };

	this.handleTabChange = this.handleTabChange.bind(this);

  }

  componentDidMount() {  
  }

  componentWillUnmount() {    
  }

  /* setLocationsGroups ( groups ) {
    let a = { }
    if ( groups !== null && groups !== undefined ) {
      groups.map ( (locationGroup) => {
        a [ locationGroup.iref ] = locationGroup;        
      });
      this.setState ( { locationGroups : a });
    }
  }
 */

  addDate = (event) => {
    this.setState ({date: event.target.value});
  }

  handleTabChange ( event, newValue ) {
	// this.addDate = this.addDate.bind(this);  
    this.setState ( { tabValue: newValue } );
	// console.log(this.state.date);
  }

  render() {

	let tabValue = "value";
    if ( this.state.tabValue ) tabValue = this.state.tabValue;

		return (
			<Box component="main" sx={{flexGrow: 1, py: 8, paddingTop: "30px", paddingBottom: "30px"}}>
				<Container style={{paddingLeft: 5, paddingRight:5}} maxWidth="xl">
					<Grid container spacing={1} sx={{width: 'calc(100% + 60px)', marginLeft: "-32px"}}>
						<Grid item xs={12} sm={6} lg={3}>
							<OverviewTotalSales />
						</Grid>
						<Grid item xs={12} sm={6} lg={3}>
						<OverviewTotalTransactions />
						</Grid>
						<Grid item xs={12} sm={6} lg={3}>
							<OverviewMonthSales />
						</Grid>
						<Grid item xs={12} sm={6} lg={3}>
							<OverviewMonthCustomers />
						</Grid>
						<Grid item xs={12} lg={12} >
							<Card style={{ height: '640px' }} sx={{ borderRadius:"16px"}}>
								<Box sx={{ marginLeft: "10px", borderRadius:"16px", display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-start'}} >
								<TextField
								size='small'
								id="date"
								label="date"
								type="date"
								// defaultValue={currentDate}
								value={this.state.date}
								// inputFormat="DD/MM/YYYY"
								sx={{ marginLeft: "10px", color: "#e3e3e5", marginLeft: "6px", width:120}}
								InputLabelProps={{
									shrink: true,
									sx : { color: "#5b5b5b" }
								}}
								InputProps={{ sx : { color: "#5b5b5b" }}}
								inputProps={{ style : { color: "#5b5b5b", paddingRight: 5, paddingLeft: 5} }}
								onChange={this.addDate}
								/>	
								<Tabs
								// style={{maxHeight: "20px", padding: "2px"}}
								value={tabValue}
								onChange={this.handleTabChange}
								// centered
								indicatorColor="secondary"
								>
								<Tab sx={{maxHeight: "20px", minHeight: "48px"}} label="Val" value="value" icon={<PointOfSaleIcon />} iconPosition="start"/>
								<Tab sx={{maxHeight: "20px", minHeight: "48px"}} label="Trans" value="transaction" icon={<PeopleIcon />} iconPosition="start"/>
								</Tabs>
								</Box>							
								{ tabValue === "value" ?
									<SalesPeriodLocationList date={this.state.date} />
									: ""
								}
								{ tabValue === "transaction" ?
										<SalesTransPeriodLocationList date={this.state.date}/>
										: ""
								}
							</Card>
						</Grid>		
					</Grid>
				</Container>
			</Box>
		);
	}
}

export default RapSales2;
