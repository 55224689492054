import React from 'react';
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, Stack, SvgIcon, Typography, Button } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import { format, intervalToDuration } from 'date-fns';
import Chart from './chart';

import Client from '../Client';
import Config from '../ClientConfig';

function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

let d = new Date();
let today = format(d, 'yyyy-MM-dd');  // mm=week, MM=month
let yesterday = format(d.setDate(d.getDate() - 1), 'yyyy-MM-dd');
let d1Month = format(new Date(d.getFullYear(), d.getMonth(), 1), 'yyyy-MM-dd'); //beginning of the month
let dzMonth = format(new Date(d.getFullYear(), d.getMonth() + 1, 0), 'yyyy-MM-dd'); //end of the month
let d1Year = format(new Date(d.getFullYear(), 0, 1), 'yyyy-MM-dd'); //beginning of the year
let dzYear = format(new Date(d.getFullYear(), 11, 31), 'yyyy-MM-dd'); //end of the year
let d1Week = format(d.setDate(d.getDate() - d.getDay() + 1), 'yyyy-MM-dd'); //beginning of the week
let dzWeek = format(d.setDate(d.getDate() - d.getDay() + 7), 'yyyy-MM-dd'); //end of the week
let d1LWeek = format(d.setDate(d.getDate() - d.getDay() - 13), 'yyyy-MM-dd'); //beginning of last week
let dzLWeek = format(d.setDate(d.getDate() - d.getDay() + 7), 'yyyy-MM-dd'); //end of last week
// console.log(today);  
class SalesYTDProductChart extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      salesproduct_list: [],
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);
    
  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    // today = '2024-02-25'
    let queryData = { 
      "type": 5,
      "dateFrom": today,
      "dateTo": today
  }
	  let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { salesproduct_list: data });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    this.getData ( );
  }
  
  render() {

    let productSalesData = [];
		let productSalesList = [];
		if (this.state.salesproduct_list){
			if (this.state.salesproduct_list.result) {
				let counter = 0;
				Object.keys(this.state.salesproduct_list.result).forEach(key => {
					let fData = this.state.salesproduct_list.result [ key ];
					let myData = {'x': fData.name, 'y': (parseInt(fData.value))};
					let p = {
						id: counter+1,
						name: fData.name,
						quantity: fData.quantity,
						value: thousandsSeparator(parseInt(fData.value))
					}
					if (counter<10){
						productSalesData.push ( myData );
					}
					counter += 1;
					productSalesList.push ( p );
				});
			}
    }

    return (
      <Card style={{ height: '500px' }} sx={{ borderRadius:"16px", height: '100%' }}>
      <CardHeader
        action={(
          <Button
            color="inherit"
            size="small"
            startIcon={(
              <SvgIcon fontSize="small">
                <AutorenewIcon />
              </SvgIcon>
            )}
          >
            Sync
          </Button>
        )}
        title="Sales Today"
      />
      <CardContent>
        <Chart
          height={350}
          type="bar2"
          series={[
            {	
              name: 'Sales YTD',
              data: productSalesData
            }
          ]}
          width="100%"
        />
      </CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          color="inherit"
          endIcon={(
            <SvgIcon fontSize="small">
              <ArrowForwardIcon />
            </SvgIcon>
          )}
          size="small"
        >
          Overview
        </Button>
      </CardActions>
    </Card>
    );

  }
}

export default SalesYTDProductChart;