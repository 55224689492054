import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import OverviewTotalSales from './components/biReports/OverviewTotalSales';
// import OverviewProductSales from './components/biReports/OverviewProductSales';
import OverviewProductSales_multiple from './components/biReports/OverviewProductSales_multiple';
import OverviewProductSales from './components/biReports/OverviewProductSales';
import OverviewClassSales from './components/biReports/OverviewClassSales';
import OverviewCategorySales from './components/biReports/OverviewCategorySales';
import OverviewProductsProgress from './components/biReports/OverviewProductsProgress';
import OverviewLatestProducts from './components/biReports/OverviewLatestProducts';
import SalesYTDProductChart from './components/biReports/SalesYTDProductChart';
import SalesYTDProductList from './components/biReports/SalesYTDProductList';
import SalesYTDCategoryChart from './components/biReports/SalesYTDCategoryChart';
import ProdLocationChartGroup from './components/biReports/ProdLocationChartGroup'
import CategLocationChartGroup from './components/biReports/CategLocationChartGroup';
import ProductChartGroup from './components/biReports/ProductChartGroup'

import Client from './components/Client';
import Config from './components/ClientConfig';

const now = new Date();
class RapSales2 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      /* locationGroups: null, */
      edit: false,
    };

 
  }

  componentDidMount() {    
  }

  componentWillUnmount() {    
  }

  /* setLocationsGroups ( groups ) {
    let a = { }
    if ( groups !== null && groups !== undefined ) {
      groups.map ( (locationGroup) => {
        a [ locationGroup.iref ] = locationGroup;        
      });
      this.setState ( { locationGroups : a });
    }
  } */

  
  render() {

		return (
			<Box component="main" sx={{flexGrow: 1, py: 8, paddingTop: "30px", paddingBottom: "30px"}}>
			<Container style={{paddingLeft: 5, paddingRight:5}}  maxWidth="xl">
			  <Grid container spacing={3}>
				<Grid item xs={12} sm={6} lg={3}>
					<OverviewTotalSales />
				</Grid>
				<Grid item xs={12} sm={6} lg={3}>
					<OverviewProductsProgress />
				</Grid>
				<Grid item xs={12} sm={6} lg={3}>
					<OverviewTotalSales />
				</Grid>
				<Grid item xs={12} sm={6} lg={3}>
					<OverviewProductsProgress />
				</Grid>
				{/* <Grid item xs={12} lg={12}>
					<OverviewProductSales/>
				</Grid> */}
				<Grid item xs={12} lg={12}>
					<OverviewClassSales/>
				</Grid>
				{/* <Grid item xs={12} lg={12}>
					<OverviewCategorySales/>
				</Grid> */}
				{/* <Grid item xs={12} md={6} lg={6}>
					<ProductChartGroup />
				</Grid>		 */}			
				{/* <Grid item xs={12} md={6} lg={6}>
					<ProdLocationChartGroup />
				</Grid> */}
	
				{/* <Grid item xs={12} md={6} lg={6}>
					<SalesYTDProductChart />
				</Grid>
				<Grid item xs={12} md={6} lg={6}>
					<SalesYTDProductList />
				</Grid> */}
		
				{/* <Grid item xs={12} md={6} lg={4}>
					<OverviewLatestProducts />
				</Grid> */}
				<Grid item xs={12} md={6} lg={6}>
					<CategLocationChartGroup />
				</Grid>		
				<Grid item xs={12} md={6} lg={6}>
					<SalesYTDCategoryChart />
				</Grid>		
			  </Grid>
			</Container>
		  </Box>
		);
	}
}

export default RapSales2;
