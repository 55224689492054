import React from 'react';
import { Avatar, Box, Card, CardContent, CardHeader, Stack, SvgIcon, Typography, Button } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import IconButton from '@mui/material/IconButton';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import Chart from './chart';

import Client from '../Client';
import Config from '../ClientConfig';


export const iconMap = {
  Male: (
    <SvgIcon>
      <ManIcon color="success" />
    </SvgIcon>
  ),
  Female: (
    <SvgIcon>
      <WomanIcon color="secondary"/>
    </SvgIcon>
  ),
  Undefined: (
    <SvgIcon>
      <MoreHorizIcon color="warning" />
    </SvgIcon>
  ),
  'Not Specified': (
    <SvgIcon>
      <NotInterestedIcon color="primary"/>
    </SvgIcon>
  )
};  
  
class SalesYTDCategoryChart extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      salescategory_list: [],
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    let queryData = { "type": 6 }
    let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { salescategory_list: data });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    this.getData ( );
  }

  render() {

    let categoryLabels = [];
		let categoryData = [];
		if (this.state.salescategory_list){
			if (this.state.salescategory_list.result) {
				Object.keys(this.state.salescategory_list.result).forEach(key => {
					let fData = this.state.salescategory_list.result [ key ];			
					categoryLabels.push ( fData.name );
					categoryData.push ( parseInt(fData.value) );
				});
			}
		}

    return (
        // <Card style={{ height: '600px' }} sx={{ borderRadius:"16px", height: '100%' }}>
        <Card variant="outlined" style={{ height: '600px' }} sx={{ paddingTop:"6px", borderRadius:"16px", height: '100%' }}>
          <CardHeader title="Categories Sales Today" />
          <CardContent>
            <Chart
              height={300}
              type="pie"
              series={categoryData}
              labels={categoryLabels}
              width="100%"
            />
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="center"
              spacing={2}
              sx={{ mt: 2 }}
            >
              {categoryData.map((item, index) => {
                const label = categoryLabels[index];

                return (
                  <Box
                    key={label}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: "100%",
                      borderRadius: "16px",
                      justifyContent: "center"
                    }}
                  >
                  <Typography
                      sx={{ my: 1 }}
                      variant="h8"
                    >
                      {label}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="subtitle2"
                    >
                      {item}
                    </Typography>  
                  </Box>
                );
              })}
            </Stack>
          </CardContent>
        </Card>
    );

  }
}

export default SalesYTDCategoryChart;