//import dynamic from "next/dynamic";
import React from 'react';
import { styled } from '@mui/material/styles';
import ApexChart from 'react-apexcharts';
import { ChartOptions_bar1, ChartOptions_bar2, ChartOptions_bar2v, ChartOptions_bar2k, ChartOptions_donut, ChartOptions_pie, ChartOptions_line, ChartOptions_line01, ChartOptions_line1, ChartOptions_line11 } from './ChartOptions';

class Chart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
    
  }

  render() {

    let height = 350;
    let options =  {
      chart: {
        // id: 'mychart',
        // type: 'pie'
      },
      type: '',
      labels: [/* ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'] */]
    };
    let series = [/* {
      data: [1,2,3,4,5]
      } */];
    let yaxis = [];

    if (this.props ) {
      switch ( this.props.type ) {
        case "bar1":
          options = ChartOptions_bar1;
          this.type = 'bar';
          // console.log(options);
          break;
        case "bar2":
          options = ChartOptions_bar2;
          this.type = 'bar';
          break;  
        case "bar2v":
          options = ChartOptions_bar2v;
          this.type = 'bar';
          break;            
        case "bar2k":
          options = ChartOptions_bar2k;
          this.type = 'bar';
          break;
        case "line0":
          options = ChartOptions_line;
          this.type = 'line';
          break;
        case "line01":
          options = ChartOptions_line01;
          this.type = 'line';
          break;  
        case "line1":
          options = ChartOptions_line1;
          this.type = 'line';
          break;      
        case "line11":
          // options = ChartOptions_line11;
          this.type = 'line';
          break;                         
        case "donut":
          options = ChartOptions_donut;
          this.type = this.props.type;
          this.labels = {"labels":this.props.labels};
          options = Object.assign( {}, options, this.labels);
          /* console.log(options);
          console.log(this.labels); */
          break;
        case "pie":
            options = ChartOptions_pie;
            this.type = this.props.type;
            this.labels = {"labels":this.props.labels};
            options = Object.assign( {}, options, this.labels);
            break;  
        default:
          console.log(`Sorry, we are out of ${this.props.type}.`);
      }
      if (this.props.height ) height = this.props.height;
      if (this.props.series ) series = this.props.series;
      // if (this.props.yaxis ) yaxis = this.props.yaxis;

      /* if (this.props.type == "donut") options = ChartOptions_donut; */
      if (this.props.options ) options = this.props.options; 

    }
    return (
      <ApexChart height={height} type={this.type} options={options} series={series} yaxis={yaxis} labels={this.labels}/>
    );

  }

}

export default Chart;

/* const ApexChart = dynamic(() => import('react-apexcharts'), {
  ssr: false,
  loading: () => null
});

export const Chart = styled(ApexChart)``; */